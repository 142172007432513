<template>
    <div>
		<page-title :heading=heading :subheading=subheading></page-title>
		

		<a-card :bordered="false" class="header-solid px-5 mb-5 pb-1" :bodyStyle="{padding: 0, paddingTop: '16px', marginBottom: '90px !important'}">
			<a-row class="pt-3">
				<a-col :span="12">
					<h5 class="font-semibold pt-4">User Details</h5>
				</a-col>
				<a-col :span="12" class="text-right mb-2">
					<a-button id="txtBtn" type="text" size="default" class="txtBtn mr-3 pt-3" style="color: #FB8C00; font-size: 16px;" @click="onEditItem">
						<a-icon type="form" /> Edit Profile
					</a-button>
					<a-button type="primary" :href="`/user-management/users`" class="">
						<a-icon type="arrow-left" theme="outlined" class="text-white mr-0" />Back
					</a-button>
				</a-col>
				

				<a-col :span="24">
					<a-divider class="mt-1 pt-0"/>
				</a-col>
			</a-row>
			
			<div class="pb-0 mb-0">
			
				<a-row :gutter="24">
					<a-col :span="24" :md="12" class="pb-4">
						<label><strong>First Name</strong></label>
						<a-input 
							disabled
							v-model="user.firstName"
							/>
					</a-col>

					<a-col :span="24" :md="12" class="pb-4">
						<label><strong>Last Name</strong></label>
						<a-input 
							disabled
							v-model="user.lastName"
							/>
					</a-col>
				</a-row>


				<a-row :gutter="24">
					<a-col :span="24" :md="12" class="pb-4">
						<label><strong>Gender</strong></label>
						<a-input 
							disabled
							v-model="user.gender"
							/>
					</a-col>

					<a-col :span="24" :md="12" class="pb-4">
						<label><strong>Role</strong></label>
						<a-input 
							disabled
							v-model="user.role"
							/>
					</a-col>
				</a-row>


				<a-row :gutter="24">

					<a-col :span="24" :md="12" class="pb-4">
						<label><strong>Phone Number</strong></label>
						<a-input 
							disabled
							v-model="user.phone"
							/>
					</a-col>

					<a-col :span="24" :md="12" class="pb-4">
						<label><strong>Email Address</strong></label>
						<a-input 
							disabled
							v-model="user.email"
							/>
					</a-col>

				</a-row>

				<a-row :gutter="24" class="pb-0">

					<a-col :span="24" :md="12" class="pb-4">
						<label><strong>Status</strong></label>
						<a-input 
							disabled
							v-model="user.status"
							/>
					</a-col>

				</a-row>
				
			</div>
		</a-card>



		<!-- FORM MODAL -->
		<a-modal v-model="user.showModal" :footer="null">
			<h4 class="text-center">{{ user.isCreate ? 'Add Profile Details' : 'Edit Profile Details'}}</h4>
			<a-form
                :form="form"
                class="mb-0 pb-0"
                layout="vertical"
				@submit.prevent="handleSubmit">

				<a-form-item 
					class="my-0 mt-4 pb-0" 
					label="First Name"
					:colon="false">
						<a-input 
							name="firstName"
							v-decorator="['firstName', { rules: [{ required: true, message: 'Please enter first name of the user!' }] }]"
							placeholder="First Name"
							/>
				</a-form-item>

				<a-form-item 
					class="my-0 mt-4 pb-0" 
					label="Last Name"
					:colon="false">
						<a-input 
							name="lastName"
							v-decorator="['lastName', { rules: [{ required: true, message: 'Please enter last name of the user!' }] }]"
							placeholder="Last Name"
							/>
				</a-form-item>
				
				<a-form-item 
					class="my-0 mt-4 pb-0" 
					label="Gender"
					placeholder="Select Gender"
					:colon="false">
					<a-select placeholder="Select Gender" 
						name="gender"
						v-decorator="[ 'gender',
						{ rules: [{ required: true, message: 'Please select gender of the user!' }] },]">
						<a-select-option v-for="option in genders"
							:value="option.uuid"
							:key="option.name">
							{{ option.name }}
						</a-select-option>
					</a-select>
				</a-form-item>

				<a-form-item class="my-0 mt-4 pt-2 pb-2"> 
					<a-button type="primary" color="#12A68B" :loading="user.loading" html-type="submit" class="">
						{{ user.isCreate ? 'Create' : 'Update' }}
					</a-button>
				</a-form-item>

			</a-form>
			
		</a-modal>
		

    </div>
  </template>
  
  <script>
  
    import PageTitle from "../../Layout/Components/PageTitle.vue";

    const rules = {
      name: [
        { required: true, message: 'Please input Activity name', trigger: 'blur' },
      ],
    };
  
    export default {
		components: {
			PageTitle,
		},
		data() {
			return {
            heading: 'Settings',
            subheading: 'Medicine Types',
            icon: 'pe-7s-drawer icon-gradient bg-happy-itmeo',
    
            fields: [ 'first_name', 'last_name', 'age' ],
            items: [
            { age: 40, first_name: 'Dickerson', last_name: 'Macdonald' },
            { age: 21, first_name: 'Larsen', last_name: 'Shaw' },
            { age: 89, first_name: 'Geneva', last_name: 'Wilson' }
            ],
            striped: false,
            bordered: false,
            outlined: false,
            small: false,
            hover: false,
            dark: false,
            fixed: false,
            footClone: false,

            message: '',
			countryCode: '255',
			roles: [],
			genders: [
				{ uuid: "Male", name: "Male" },
				{ uuid: "Female", name: "Female" },
			],
            
            form: '',
            rules,

			user: {
				uuid: null,
				firstName: null,
				lastName: null,
				email: null,
				phone: null,
				role: null,
				gender: null,
				roleUuid: null,
				status: null,
				isActive: null,
                isCreate: true,
                loading: false,
                showModal: false,
			},


            deleteDataItem: {
                uuid: null,
                showModal: false,
            },

            userDetails: {
                user: null,
                company: null
            },

			statusReset: {
				loading: false,
				showModal: false,
			},

			resetPassword: {
				loading: false,
				showModal: false,
			},

			hasPermission: true,

            notify: {
                text: null,
                type: null,
            },
        }
		},
		beforeCreate() {
			// Creates the form and adds to it component's "form" property.
			this.form = this.$form.createForm(this, { name: 'newItemForm' });
		},
      computed: {
            to () {
                let highBound = this.from + this.pagination.perPage
                if (this.total < highBound) {
                    highBound = this.total
                }
                return highBound
            },
            from () {
                return this.pagination.perPage * (this.pagination.currentPage - 1)
            },
        },
		watch: {
            pagination: {
                handler() {
                    this.getPharmacyMedicines();
                },
            },
            deep: true,
        },
		created() {
			this.form = this.$form.createForm(this, { name: 'newItemForm' })
            this.getUserDetails();
			this.getUsersDetails();
		},
		methods: {

            async getUserDetails() {
                let userDetails = await JSON.parse(localStorage.getItem("pharmUser"));

                if(userDetails != null) {
                    this.userDetails.user = userDetails.user;
                    this.userDetails.company = userDetails.company;
                }else {
                    this.$router.push(`/`);
                }

				// const role = userDetails != null && userDetails.role != null ? userDetails.role.codeName : null;

                // if(role == null) {
                //     this.$router.push(`/`);
                // }

                // let permissions = user.role.permissions !== null ? user.role.permissions.map(perm => perm.codeName.toLowerCase()) : [];

                // if(permissions != null && permissions.length > 0) {

                //     if(permissions.includes("authorize-drug")) {
                //         this.hasPermission = true;
                //     }else{
                //         this.hasPermission = false
                //     }
                // }
            },


			getActiveStage() {
                let show = false;
                let tracker = this.records.filter(track => track.isActive && track.stage.stageNumber == 3)

                if(tracker.length == 1) {
                    show = true;
                }

                return show
            },


			async getUsersDetails() {

				let userDetails = await JSON.parse(localStorage.getItem("pharmUser"));
                
                let url = `${this.$BACKEND_URL}/users/show/${userDetails.uuid}`;

                this.$AXIOS.get(url).then((response) => {
                    if (response.status == 200) {
						this.user.uuid = response.data.uuid;
						this.user.firstName = response.data.firstName;
						this.user.lastName = response.data.lastName;
						this.user.email = response.data.email;
						this.user.phone = `0${response.data.phone.substring(4)}`;
						this.user.gender = response.data.gender;
						this.user.role = response.data.role != null ? response.data.role.name : null;
						this.user.roleUuid = response.data.role != null ? response.data.role.uuid : null;
						this.user.status = response.data.isActive ? "Active" : "Inactive"
						this.user.isActive = response.data.isActive;
                    }
					
                }).catch((error) => {
                    if(error.response != null && error.response.status == 401) {
                        this.$router.push(`/`);
                    }

                    // eslint-disable-next-line no-console
                    // console.log(error)

                    this.notify.text = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
                    this.notify.type = "error"

                    this.$notify(this.notify)

                });
            },


			async onEditItem() {
				// this.form = this.$form.createForm(this, { name: 'newItemForm' })
				this.user.isCreate = false;
				this.user.showModal = true;
                this.user.uuid = this.user.uuid;
                this.user.companyUuid = this.userDetails.company.uuid;
				this.user.loading = false;
				// eslint-disable-next-line no-console
				// console.log(item)
				setTimeout(() => {
					this.form.setFieldsValue({
						firstName: this.user.firstName,
						lastName: this.user.lastName,
						gender: this.user.gender,
					});
				}, 10);

            },

			async handleSubmit() {

				this.form.validateFields((err, values) => {
					if ( !err ) {

						this.user.loading = true;

						let url = `${this.$BACKEND_URL}/users/${this.user.uuid}`

						let payload = values;

						this.$AXIOS.patch(url, payload).then(response => {

							if (response.status >= 200 && response.status < 210) {

								this.form.resetFields()

								this.user.isCreate = true;
								this.user.loading = false;
								this.user.showModal = false;

								this.notify.text = "Details were updated successfully"
								this.notify.type = "success"
								

								this.$notify(this.notify)

							}

							this.getUsersDetails()
			
						}).catch(error => {
						
							this.user.loading = false;
				
							if(error.response != null && error.response.status == 401) {
								this.$router.push(`/`);
							}

							this.notify.text = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
							this.notify.type = "error"

							this.$notify(this.notify)
						});
					}
				})
				
			},

		}


    }
  </script>
  
  <style lang="scss">
  
  #textBtn, #textBtn:hover {
      border: none !important;
  }
</style>